import React, { useEffect } from "react";

function InquiryPopup(props) {
  function closeModal() {
    props.closeModal();
    enableScroll();
  }
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const disableScroll = () => {
    window.onscroll = () => {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };

  const enableScroll = () => {
    window.onscroll = () => {};
  };

  return (
    <div className="z-50 Modal" onClick={closeModal}>
      <div
        className="modalBody Mid:h-[710px] Mobile:h-[580px] Big:w-[1046px] Mobile:mx-[16px] Mid:mx-0"
        onClick={(e) => e.stopPropagation()}
        onAppear={disableScroll()}
      >
        {props.children}
      </div>
      <button id="modalCloseBtn" className="text-white" onClick={closeModal}>
        ✕
      </button>
    </div>
  );
}
export default InquiryPopup;
