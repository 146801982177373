import React from "react";

function PageMain4() {
  return (
    <div className="grid Mid:h-[427px] Mobile:h-[226px] grid-cols-1 place-content-center place-items-center Big:px-0 Mid:px-[5vw]">
      <div className="flex Big:flex-row Mobile:flex-col text-deepdarkGray">
        <section className="Big:pr-[173px] Mid:pr-[13vw] Mobile:mb-[36px]">
          <div className="Mid:text-[26px] Mobile:text-[16px] font-semibold Mid:mb-[15px] Mobile:mb-[8px]">
            성공적인 센터 운영의 비결
          </div>
          <div className="Mid:text-[16px] Mobile:text-[12px] text-darkGray">
            운동 분석 솔루션 WORKWAY와 함께
            <br />
            센터의 매출을 끌어올리세요.
          </div>
        </section>
        <section className="flex flex-row -translate-y-5">
          <section className="Big:pr-[102px] Mid:pr-[7vw] Mobile:pr-[26px]">
            <span className="Mid:text-sm Mobile:text-[10px] Mid:font-semibold Mobile:font-normal text-whiteGray">
              .
            </span>
            <div className="Mid:text-lg Mobile:text-[10px] font-semibold text-center text-darkGray">
              워크인 회원 유입
            </div>
            <div className="text-center Mid:text-[40px] Mobile:text-[20px] font-semibold flex">
              70%p↑
            </div>
          </section>
          <section className="Big:pr-[102px] Mid:pr-[7vw] Mobile:pr-[26px]">
            <span className="Mid:text-sm Mobile:text-[10px] Mid:font-semibold Mobile:font-normal text-whiteGray">
              .
            </span>
            <div className="Mid:text-lg Mobile:text-[10px] font-semibold text-center text-darkGray">
              PT 수업 만족도
            </div>
            <div className="text-center Mid:text-[40px] Mobile:text-[20px] font-semibold">
              2배+
            </div>
          </section>
          <section className="Big:pr-[102px] Mid:pr-[7vw] Mobile:pr-[26px] Mobile:pt-2 Mid:pt-0">
            <div className="flex flex-col text-center">
              <span className="Mid:text-sm Mobile:text-[10px] Mid:font-semibold Mobile:font-normal text-mediumGray">
                OT 1회 후
              </span>
              <span className="Mid:text-lg Mobile:text-[10px] font-semibold">
                PT 수업 등록률
              </span>
            </div>
            <div className="text-center Mid:text-[40px] Mobile:text-[20px] font-semibold">
              80%
            </div>
          </section>
          <section>
            <span className="Mid:text-sm Mobile:text-[10px] Mid:font-semibold Mobile:font-normal text-whiteGray">
              .
            </span>
            <div className="Mid:text-lg Mobile:text-[10px] font-semibold text-center text-darkGray">
              영업 이익
            </div>
            <div className="text-center Mid:text-[40px] Mobile:text-[20px] font-semibold flex">
              50%p↑
            </div>
          </section>
        </section>
      </div>
    </div>
  );
}

export default PageMain4;
