import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import tw from "tailwind-styled-components";

const Section = tw.section`
  rounded-b-lg
  Big:pt-[40px] Mid:pt-[3vw] Mobile:pt-[16px]
  Big:pl-[27px] Mid:pl-[2vw] Mobile:pl-[16px]
  Big:pb-[34px] Mid:pb-[2vw] Mobile:pb-[16px] 
  Big:pr-[39px] Mid:pr-[3vw]
`;
function Item1() {
  return (
    <div
      className="
      Big:w-[675px] Mid:w-[51vw] Mobile:w-[343px] Mid:h-[700px] Mobile:h-auto
      bg-white rounded-[20px]"
    >
      <section className="rounded-lg ">
        <div
          className="bg-[url('./images/pageService/pageService3_2.png')] 
        Big:w-[675px] Mid:w-[51vw] Mobile:w-[343px] Big:h-[420px] Mid:h-[34vw] Mobile:h-[213px]
        bg-contain bg-no-repeat"
        />
        <Section>
          <div className="Big:pb-[11px] Mid:pb-[0.9vw] leading-none Mid:text-[16px] Mobile:text-[12px] text-Blue">
            근전도 측정 Wearable
          </div>
          <div className="Big:pb-[25px] Mid:pb-[2vw] Mid:text-3xl Mobile:text-[16px] font-semibold">
            감이 아닌 데이터로 근육의 활성도를 확인하세요
          </div>
          <div className="Mid:text-[18px] Mobile:text-[12px] font-normal text-mediumGray">
            근전도 측정 기술을 활용한 웨어러블 기기는 부위별로
            <br /> 가용되는 근육의 활성도를 정량적인 데이터로 도출합니다.
          </div>
        </Section>
      </section>
    </div>
  );
}

function Item2() {
  return (
    <div
      className="
    Big:w-[675px] Mid:w-[51vw] Mobile:w-[343px] Mid:h-[700px] Mobile:h-auto 
    bg-white rounded-[20px]"
    >
      <section className="rounded-lg pt-[27px]">
        <div
          className="bg-[url('./images/pageMain/pageMain3-2.png')] 
      Big:w-[516px] Mid:w-[39vw] Mobile:w-[247px]
      Big:h-[331px] Mid:h-[27vw] Mobile:h-[159px]
      Big:mx-[80px] Mid:mx-[6vw] Mobile:mx-[47px]
      Big:my-[45px] Mid:my-[3vw] Mobile:mb-[27px]
      bg-contain bg-no-repeat"
        />
        <Section>
          <div className="Big:pb-[11px] Mid:pb-[0.9vw] leading-none Mid:text-[16px] Mobile:text-[12px] text-Blue">
            AI 데이터 분석 솔루션
          </div>
          <div className="Big:pb-[25px] Mid:pb-[2vw] Mid:text-3xl Mobile:text-[16px] font-semibold">
            AI가 실시간으로 내 운동 데이터를 분석해 줘요
          </div>
          <div className="Mid:text-[18px] Mobile:text-[12px] font-normal text-mediumGray">
            WORKWAY의 자체 개발 AI가 부위별 근육 활성도 데이터를
            <br /> 실시간으로 분석 및 도출하여 운동 리포트를 제공합니다.
          </div>
        </Section>
      </section>
    </div>
  );
}

function Item3() {
  return (
    <div
      className="
  Big:w-[675px] Mid:w-[51vw] Mobile:w-[343px] Mid:h-[700px] Mobile:h-auto 
  bg-white rounded-[20px]"
    >
      <section className="rounded-lg">
        <div
          className="bg-[url('./images/pageService/pageService3_2_3.png')] 
        Big:w-[675px] Mid:w-[51vw] Mobile:w-[343px] Big:h-[420px] Mid:h-[34vw] Mobile:h-[213px]
        bg-contain bg-no-repeat"
        />
        <Section>
          <div className="Big:pb-[11px] Mid:pb-[0.9vw] leading-none Mid:text-[16px] Mobile:text-[12px] text-Blue">
            자동 인식 횟수 카운트{" "}
          </div>
          <div className="Big:pb-[25px] Mid:pb-[2vw] Mid:text-3xl Mobile:text-[16px] font-semibold">
            운동에만 집중할 수 있도록, 횟수는 저희가 셀게요{" "}
          </div>
          <div className="Mid:text-[18px] Mobile:text-[12px] font-normal text-mediumGray">
            WORKWAY의 데이터 분석 기술을 통해 자동으로 운동 횟수를 세어
            드립니다.
            <br />
            횟수별 운동 리포트 역시 별도의 입력 없이 즉시 확인할 수 있습니다.
          </div>
        </Section>
      </section>
    </div>
  );
}

function Item4() {
  return (
    <div
      className="
    Big:w-[675px] Mid:w-[51vw] Mobile:w-[343px] Mid:h-[700px] Mobile:h-auto 
    bg-white rounded-[20px]"
    >
      <section className="rounded-lg pt-[27px]">
        <div
          className="bg-[url('./images/pageService/pageService3_2_4.png')] 
      Big:w-[516px] Mid:w-[39vw] Mobile:w-[247px]
      Big:h-[331px] Mid:h-[27vw] Mobile:h-[159px]
      Big:mx-[80px] Mid:mx-[6vw] Mobile:mx-[47px]
      Big:my-[45px] Mid:my-[3vw] Mobile:mb-[27px]
      bg-contain bg-no-repeat"
        />
        <Section>
          <div className="Big:pb-[11px] Mid:pb-[0.9vw] leading-none Mid:text-[16px] Mobile:text-[12px] text-Blue">
            AI 데이터 분석 솔루션
          </div>
          <div className="Big:pb-[25px] Mid:pb-[2vw] Mid:text-3xl Mobile:text-[16px] font-semibold">
            AI가 실시간으로 내 운동 데이터를 분석해 줘요
          </div>
          <div className="Mid:text-[18px] Mobile:text-[12px] font-normal text-mediumGray">
            WORKWAY의 자체 개발 AI가 부위별 근육 활성도 데이터를
            <br /> 실시간으로 분석 및 도출하여 운동 리포트를 제공합니다.
          </div>
        </Section>
      </section>
    </div>
  );
}
function PageService3_2() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });
  return (
    <div
      className="Mid:mt-[143px] Mid:h-[1063px] Mobile:h-[1837px] flex flex-col 
    Mid:text-[40px] Mobile:text-[20px] font-normal Mid:mb-[600px] Mobile:my-[158px]"
    >
      <div className="flex flex-col justify-center text-center">
        최고의 기술력으로
        <div className="flex flex-row justify-center">
          <div className="font-semibold">프리미엄 피트니스 솔루션</div>을&nbsp;
          {innerWidth >= 768 && <>제시합니다</>}
        </div>
        {innerWidth < 768 && <>제시합니다</>}
      </div>
      {innerWidth >= 768 ? (
        <>
          <Carousel className="self-center Mid:mt-[50px] carousel-dark rounded-[20px] Big:w-[675px] Mid:w-[51vw] shadow">
            <Carousel.Item>
              <Item1 />
            </Carousel.Item>
            <Carousel.Item>
              <Item2 />
            </Carousel.Item>
            <Carousel.Item>
              <Item3 />
            </Carousel.Item>
            <Carousel.Item>
              <Item4 />
            </Carousel.Item>
          </Carousel>
        </>
      ) : (
        <div className="mt-[40px] h-[1334px] flex flex-col gap-[16px]">
          <div className="shadow rounded-2xl">
            <Item1 />
          </div>
          <div className="shadow rounded-2xl">
            <Item2 />
          </div>
          <div className="shadow rounded-2xl">
            <Item3 />
          </div>
          <div className="shadow rounded-2xl">
            <Item4 />
          </div>
        </div>
      )}
    </div>
  );
}

export default PageService3_2;
