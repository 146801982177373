import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as tw from "./InquiryContextStyle";

import axios from "axios";

export const InquiryContext = () => {
  //버튼
  const [clicked, setClicked] = useState(0);
  const clickCount = () => {
    setClicked(clicked + 1);
  };

  const {
    register,
    reset,
    watch,
    errors,
    getValues,
    resetField,
    handleSubmit,
  } = useForm(); //react-hook-form 을 쓰기 위함

  const onSubmit = (data) => {
    // 기본으로 data 가져오기
    console.log(data);
  };

  const onChangeTeam = watch("team")?.length ?? 0;
  const onChangeName = watch("name")?.length ?? 0;
  const onChangeEmail = watch("email")?.length ?? 0;
  const onChangeAddress = watch("address")?.length ?? 0;
  const [existTeam, setExistTeam] = useState(false);
  const [existName, setExistName] = useState(false);
  const [existEmail, setExistEmail] = useState(false);
  const [existAddress, setExistAddress] = useState(false);

  const onResetTeam = (data) => {
    //console.log(getValues("team"))
    resetField("team");
    //console.log(getValues("team"))
  };
  const onResetName = (data) => {
    resetField("name");
  };
  const onResetEmail = (data) => {
    resetField("email");
  };
  const onResetAddress = (data) => {
    resetField("address");
  };

  const [submitDisabled, setSubmitDisabled] = useState(true);

  // 제출버튼 disable/able 조정
  useEffect(() => {
    let a = !watch("team")?.length;
    let b = !watch("name")?.length;
    let c = !watch("email")?.length;
    let d = !watch("address")?.length;
    let e = clicked % 2 === 0;
    setSubmitDisabled(a || b || c || d || e);
  }, [watch("team"), watch("name"), watch("email"), watch("address"), clicked]);

  // email 전송
  const form = useRef();
  function sendMail() {
    axios
      .post(
        "https://us-central1-playground-9a08e.cloudfunctions.net/eSendEmail/",
        new URLSearchParams({
          to: watch("email"),
          subject: watch("team"),
          text: watch("name"),
        })
      )
      .then(function (response) {
        console.log("success");
        console.log(response);
      })
      .catch(function (error) {
        console.log("failed");
        console.log(error);
      });
  }

  // 체크표시 채우기/안채우기 이미지
  function NotFilledButton() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );
  }

  function FilledButton() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke-width="none"
        stroke="none"
        className="w-[20px] h-[20px] fill-bubblePurple"
      >
        <path
          fill-rule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
          clip-rule="evenodd"
        />
      </svg>
    );
  }

  // 내부 요소
  return (
    <form
      ref={form}
      // onSubmit={sendMail}
      className="flex text-start Big:flex-row Mobile:flex-col"
    >
      <div
        className="Big:w-[442px] Mobile:w-full Big:h-[710px] Mobile:h-[65px] 
      bg-[url('./images/modal.png')] bg-no-repeat bg-cover 
      Mobile:rounded-t-2xl Big:rounded-none overflow-auto"
      />
      <div className="Big:w-[604px] Big:h-[616px] Mid:h-[650px] Mobile:h-[525px] text-deepdarkGray overflow-auto self-center Mobile:mt-[20px] Big:mt-0">
        <div className="Mobile:ml-[16px] font-semibold Mid:text-[20px] Mobile:text-[16px]">
          도입 문의를 위해 아래 양식을 작성해 주세요!
        </div>
        <div
          className="Mobile:ml-[16px] 
        mb-[30px] text-darkGray
        font-normal Mid:text-[14px] Mobile:text-[12px]"
        >
          빠른 시일 내에 답변 드리겠습니다.
        </div>
        <div className="mb-3 Mobile:ml-[16px] mr-10 Mid:text-[16px]">
          <div className="w-full flex flex-col Mid:gap-y-7 Mobile:gap-y-4 Mid:text-[16px] Mobile:text-[14px]">
            <section className="flex">
              <div className="flex flex-row mt-3 font-semibold w-[100px]">
                소속<div className="text-mainRed">*</div>
              </div>
              <tw.Input
                id="team"
                type="text"
                className="team"
                name="team"
                placeholder="회사명"
                onChange={() =>
                  this.length > 0 ? setExistTeam(true) : setExistTeam(false)
                }
                {...register("team", { required: "팀을 작성해주세요" })}
              />
            </section>
            <section className="flex">
              <div className="flex flex-row mt-3 font-semibold w-[100px]">
                이름<div className="text-mainRed">*</div>
              </div>
              <tw.Input
                id="name"
                type="text"
                className="name"
                name="name"
                placeholder="이름"
                onChange={() =>
                  this.length > 0 ? setExistName(true) : setExistName(false)
                }
                {...register("name", { required: "이름을 작성해주세요" })}
              />
            </section>

            <section className="flex">
              <div className="flex flex-row mt-3 font-semibold w-[100px]">
                메일 주소<div className="text-mainRed">*</div>
              </div>
              <tw.Input
                type="email"
                className="email"
                name="email"
                placeholder="name@example.com"
                onChange={() =>
                  this.length > 0 ? setExistEmail(true) : setExistEmail(false)
                }
                {...register("email", {
                  pattern:
                    /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/,
                  required: "이메일을 올바른 형식으로 작성해주세요",
                })}
              />
            </section>

            <section className="flex">
              <div className="flex flex-row mt-3 font-semibold w-[100px]">
                전화번호<div className="text-mainRed">*</div>
              </div>
              <tw.Input
                type="text"
                className="address"
                name="address"
                placeholder="01012345678"
                onChange={() =>
                  this.length > 0
                    ? setExistAddress(true)
                    : setExistAddress(false)
                }
                {...register("address", { required: "주소를 작성해주세요" })}
              />
            </section>
            <section className="flex">
              <div className="mt-3 font-semibold w-[100px]">내용</div>
              <tw.Textarea
                type="text"
                name="message"
                rows="4"
                placeholder="문의하실 내용을 작성해 주세요."
              />
            </section>
          </div>
        </div>
        <div
          className="flex Mobile:mx-[17px] 
        Big:gap-[140px] Mid:gap-[4vw] Mobile:gap-[3px]
        Mid:mt-[55px] Mobile:mt-[24px]"
        >
          <div
            onClick={() => clickCount()}
            className="flex translate-y-3 cursor-pointer h-fit"
          >
            <div>
              {clicked % 2 === 0 ? (
                <div className="text-[20px] text-mediumGray">○</div>
              ) : (
                <FilledButton />
              )}
            </div>
            <div className="font-semibold Mobile:text-[12px] Mid:text-[16px]">
              <div className="text-mainRed">[필수]&nbsp;</div>
              개인정보 수집 및 이용 동의
            </div>
          </div>
          <button
            id="submitBtn"
            onClick={sendMail}
            disabled={submitDisabled}
            type="button"
            class="Mid:ml-11 Mobile:ml-32 Mid:w-[155px] Mobile:w-[90px] Mid:y-[46px] Mid:py-0 Mobile:py-[13px] 
            rounded-lg disabled:bg-whiteGray disabled:text-mediumGray enabled:bg-bubblePurple enabled:text-whiteGray
            Mobile:text-[12px] Mid:text-[16px]"
          >
            제출하기
          </button>
        </div>
      </div>
    </form>
  );
};
export default InquiryContext;
