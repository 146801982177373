import React, { useState, useEffect } from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import InquiryPopup from "../modals/Inquiry/InquiryPopup";
import InquiryContext from "../modals/Inquiry/InquiryContext";

function ShowNavBar() {
  const [inquiryModalOn, setInquiryModalOn] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });
  return (
    <Navbar expand="lg" className="m-0 bg-black">
      <Container data-bs-theme="dark">
        <Navbar.Brand href="/">
          <div
            className="Mid:w-[144px] Mobile:w-[132px] Mid:h-[28px] Mobile:h-[25px] 
        bg-[url('./images/workwayLogoWhite.png')] bg-contain bg-no-repeat mt-[8px]
        "
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/pages/PageService" className="text-white">
              서비스 소개
            </Nav.Link>
            <Nav.Link
              href="https://layercorp.notion.site/layercorp/WORKWAY-56cc4536b9ce4521ae4e5947bdd37918"
              className="text-white"
            >
              테스터 모집
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            <Nav.Link>
              <div
                className="text-white"
                onClick={() => setInquiryModalOn(true)}
              >
                문의하기
              </div>
              {inquiryModalOn && (
                <InquiryPopup
                  closeModal={() => setInquiryModalOn(!inquiryModalOn)}
                >
                  <InquiryContext />
                </InquiryPopup>
              )}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default ShowNavBar;
