import React, { useState, useEffect } from "react";

function PageMain5() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });
  return (
    <div className="grid Mid:h-[4381px] Mobile:h-[2336px] grid-cols-1 place-content-center place-items-center">
      <section className="text-center text-black Mid:text-[40px] Mobile:text-[20px] font-normal Mid:mb-[600px] Mobile:my-[150px]">
        운동을&nbsp;
        <span className="text-black font-semibold underline underline-offset-[-2px] decoration-underlinePurple decoration-8">
          직관적으로 분석
        </span>
        해 주니까{innerWidth < 782 ? <br /> : <>&nbsp;</>}회원들이 더 좋아해요
      </section>
      <section className="Mid:mb-[514px] Mobile:mb-[130px]">
        {innerWidth < 782 && (
          <div
            className="text-center text-[12px] font-semibold text-bubblePurple
      border-1 rounded-md py-[4px] px-[7px] border-bubblePurple border:radius-[2px] mb-[14px]"
          >
            일반 회원 증가
          </div>
        )}
        <div className="Mid:text-3xl Mobile:text-[16px] font-semibold text-center Mid:mb-[25px] Mobile:mb-[6px]">
          혼자서도 확실하게 내 운동을 확인할 수 있어요
        </div>
        <div className="flex justify-center mb-[26px]">
          {innerWidth >= 782 && (
            <div
              className="text-lg font-semibold text-center text-bubblePurple
      border-1 rounded-md py-[5px] px-[9px] border-bubblePurple border:radius-[2px] mr-[10px]"
            >
              일반 회원 증가
            </div>
          )}
          <div className="Mid:py-[6px] Mid:px-[9px] Mid:text-lg Mobile:text-[12px] font-normal text-center text-mediumGray">
            더 전문성 있는 센터를 찾는 회원들이 유입돼요
          </div>
        </div>
        <div className="flex gap-2 Mid:flex-row Mobile:flex-col">
          <div
            className="bg-[url('./images/pageMain/pageMain5-1-1.png')] 
          Big:w-[566px] Mid:w-[43vw] Mobile:w-[342px] Big:h-[363px] Mid:h-[30vw] Mobile:h-[219px]
          bg-contain bg-no-repeat "
          />
          <div
            className="bg-[url('./images/pageMain/pageMain5-1-2.png')] 
          Big:w-[566px] Mid:w-[43vw] Mobile:w-[342px] Big:h-[363px] Mid:h-[30vw] Mobile:h-[219px] 
          bg-contain bg-no-repeat "
          />
        </div>
      </section>
      <section className="Mid:mb-[639px] Mobile:mb-[150px]">
        {innerWidth < 782 && (
          <div
            className="text-center text-[12px] font-semibold text-bubblePurple
      border-1 rounded-md py-[4px] px-[7px] border-bubblePurple border:radius-[2px] mb-[14px]"
          >
            PT 회원 증가
          </div>
        )}
        <div className="Mid:text-3xl Mobile:text-[16px] font-semibold text-center Mid:mb-[25px] Mobile:mb-[6px]">
          더 정확하고 만족도 높은 PT를 제공할 수 있어요{" "}
        </div>
        <div className="flex justify-center mb-[26px]">
          {innerWidth >= 782 && (
            <div
              className="text-lg font-semibold text-center text-bubblePurple
      border-1 rounded-md py-[5px] px-[9px] border-bubblePurple border:radius-[2px] mr-[10px]"
            >
              PT 회원 증가
            </div>
          )}
          <div className="Mid:py-[6px] Mid:px-[9px] Mid:text-lg Mobile:text-[12px] font-normal text-center text-mediumGray">
            영업 리소스는 줄이고, 성공률은 높이면서도 회원의 PT 만족도는
            올라가요{" "}
          </div>
        </div>
        <div className="flex gap-2 Mid:flex-row Mobile:flex-col">
          <div
            className="bg-[url('./images/pageMain/pageMain5-2-1.png')] 
          Big:w-[566px] Mid:w-[43vw] Mobile:w-[342px] Big:h-[363px] Mid:h-[30vw] Mobile:h-[219px]
          bg-contain bg-no-repeat "
          />
          <div
            className="bg-[url('./images/pageMain/pageMain5-2-2.png')] 
          Big:w-[566px] Mid:w-[43vw] Mobile:w-[342px] Big:h-[363px] Mid:h-[30vw] Mobile:h-[219px] 
          bg-contain bg-no-repeat "
          />
        </div>
      </section>
      <section className="flex flex-col text-center text-black Mid:text-[40px] Mobile:text-[20px] font-normal">
        <span>
          잠재 고객부터 PT 회원까지,{innerWidth < 850 ? <br /> : <>&nbsp;</>}
          <span
            className="text-black font-semibold underline underline-offset-[-2px] decoration-underlinePurple decoration-8
        Mid:mb-[60px] Mobile:mb-[148px]"
          >
            체계적인 유입 과정
          </span>
          을 설계해 보세요
        </span>
        <div
          className="Mid:my-[60px] Mobile:my-[150px] Mid:mx-[5.5vw]
        Big:py-[115px] Mid:py-[9vw] Big:w-[1159px] Mid:w-[89vw] Mobile:w-[343px] Big:h-[663px] Mid:h-[54vw] Mobile:h-[268px] 
        bg-white rounded-[20px] shadow"
        >
          <div
            className="Mid:bg-[url('./images/pageMain/pageMain5-3.png')] Mobile:bg-[url('./images/pageMain/pageMain5-3-mobile.png')]
          Big:w-[1006px] Mid:w-[77vw] Mobile:w-[311px] Big:h-[433px] Mid:h-[35vw] Mobile:h-[208px] 
          Big:mx-[77px] Mid:mx-[5vw] Mobile:mx-[16px] Mid:my-[0px] Mobile:my-[30px]
          bg-contain bg-no-repeat "
          />
        </div>
      </section>
    </div>
  );
}

export default PageMain5;
