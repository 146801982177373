import React, { useState, useEffect } from "react";
import InquiryPopup from "../modals/Inquiry/InquiryPopup";
import InquiryContext from "../modals/Inquiry/InquiryContext";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

function Footer() {
  const [inquiryModalOn, setInquiryModalOn] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });
  return (
    <div className="grid grid-cols-10 Mid:h-[493px] Mobile:h-[323px] bg-deepdarkGray place-content-center Mid:place-items-center Mobile:place-items-start text-whiteGray Mid:text-[20px] Mobile:text-[10px] px-[70px]">
      <div className="flex Mid:mb-[85px] Mobile:mb-[20px]">
        <div className="flex col-span-7 ">
          <div className="flex">
            <div
              className="w-[160px] h-[31px]
        bg-[url('./images/workwayLogoWhite.png')] bg-contain bg-no-repeat mt-[2px]
        "
            />
            <div className="flex">
              &nbsp;by&nbsp;
              <div className="font-semibold">Layer corp.</div>
            </div>
          </div>
        </div>
        {innerWidth >= 1410 && (
          <div className="ml-[576px] flex">
            <button
              onClick={() => setInquiryModalOn(true)}
              className="font-semibold Big:pr-[50px] Mid:pr-[3vw]"
            >
              도입 문의
            </button>
            {inquiryModalOn && (
              <InquiryPopup
                closeModal={() => setInquiryModalOn(!inquiryModalOn)}
              >
                <InquiryContext />
              </InquiryPopup>
            )}
            <button
              className="font-semibold Big:pr-[50px] Mid:pr-[3vw]"
              onClick={() => {
                window.open(
                  "https://layercorp.notion.site/0901-WorkWay-3fe7cb41f47540c2971a86b52fc1cad7"
                );
              }}
            >
              이용약관
            </button>
            <button
              className="font-semibold"
              onClick={() => {
                window.open(
                  "https://layercorp.notion.site/0901-WorkWay-4f536500e19a4134b157242bd4f870b9"
                );
              }}
            >
              개인정보처리방침
            </button>
          </div>
        )}
      </div>
      <div className="flex Big:gap-[494px] Mid:gap-[38vw]">
        {innerWidth >= 1410 ? (
          <div className="flex flex-col gap-[16px]">
            <div className="flex flex-row">
              주식회사&nbsp;
              <div className="font-semibold">레이어코퍼레이션</div>
            </div>
            <div className="flex flex-row">
              대표&nbsp;
              <div className="font-semibold">김성현&nbsp;</div>|
              사업자등록번호&nbsp;
              <div className="font-semibold">439-86-02971</div>
            </div>
            부산광역시 금정구 부산대학로 63번길2, 효원산학협동관 411호
          </div>
        ) : (
          <div className="flex flex-col gap-[16px] mb-[30px]">
            <div className="flex flex-row">
              대표&nbsp;
              <div className="font-semibold">김성현&nbsp;</div>|
              사업자등록번호&nbsp;
              <div className="font-semibold">439-86-02971</div>
            </div>
            <div className="flex flex-row">
              주식회사&nbsp;
              <div className="font-semibold">레이어코퍼레이션</div>
            </div>
            부산광역시 금정구 부산대학로 63번길2, 효원산학협동관 411호
          </div>
        )}

        {innerWidth >= 1410 && (
          <div className="mt-[68px] text-end font-semibold">
            ⓒ Layer Corperation Co.Ltd
            <div className="text-[14px] font-thin">
              Designed by&nbsp;
              <button
                className="underline underline-offset-2"
                onClick={() => openInNewTab("https://www.freepik.com")}
              >
                Freepik
              </button>
            </div>
          </div>
        )}
      </div>
      {innerWidth < 1410 && (
        <div className="flex flex-row gap-[20px]">
          <button
            onClick={() => setInquiryModalOn(true)}
            className="font-semibold Big:pr-[50px] Mid:pr-[3vw]"
          >
            도입 문의
          </button>
          {inquiryModalOn && (
            <InquiryPopup closeModal={() => setInquiryModalOn(!inquiryModalOn)}>
              <InquiryContext />
            </InquiryPopup>
          )}
          <button
            className="font-semibold Big:pr-[50px] Mid:pr-[3vw]"
            onClick={() => {
              window.open(
                "https://layercorp.notion.site/0901-WorkWay-3fe7cb41f47540c2971a86b52fc1cad7"
              );
            }}
          >
            이용약관
          </button>
          <button
            className="font-semibold"
            onClick={() => {
              window.open(
                "https://layercorp.notion.site/0901-WorkWay-4f536500e19a4134b157242bd4f870b9"
              );
            }}
          >
            개인정보처리방침
          </button>
        </div>
      )}
      {innerWidth < 1410 && (
        <div className="mt-[58px] ml-[80px] text-end font-semibold">
          ⓒ Layer Corperation Co.Ltd
          <div className="text-[8px] font-thin">
            Designed by&nbsp;
            <button
              className="underline underline-offset-2"
              onClick={() => openInNewTab("https://www.freepik.com")}
            >
              Freepik
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
