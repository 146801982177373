import React, { useState, useEffect } from "react";
import PageMain6_photos from "./PageMain6_photos";
import YouTube from "react-youtube";

import InquiryPopup from "../../modals/Inquiry/InquiryPopup";
import InquiryContext from "../../modals/Inquiry/InquiryContext";

function PageMain6() {
  const [inquiryModalOn, setInquiryModalOn] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });

  return (
    <div className="grid Mid:h-[3900px] Mobile:h-[3036px] grid-cols-1 place-content-center place-items-center">
      <section className="text-center Mid:mb-[484px] Mobile:mb-[1000px] Mid:mt-[164px] Mobile:mt-[450px] w-full Big:px-0 Mid:px-[70px]">
        <div className="Mid:text-3xl Mobile:text-[14px] font-normal text-whiteGray Mid:mb-[67px] Mobile:mb-[20px]">
          세상에 없던
          <div className="Mid:text-[50px] Mobile:text-[20px] font-semibold Mid:mt-[16px] Mobile:mt-[6px]">
            직관적인 운동 경험의 시작
          </div>
        </div>
        <div className="Mid:h-[650px] Mobile:h-[194px] w-full">
          <YouTube
            //videoId : https://www.youtube.com/watch?v={videoId} 유튜브 링크의 끝부분에 있는 고유한 아이디
            videoId="_9bjrNLSfdY"
            //opts(옵션들): 플레이어의 크기나 다양한 플레이어 매개 변수를 사용할 수 있음.
            //밑에서 더 설명하겠습니다.
            opts={{
              width: "100%",
              height: "600",
              playerVars: {
                autoplay: 0, //자동재생 O
                rel: 0, //관련 동영상 표시하지 않음 (근데 별로 쓸모 없는듯..)
                modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
              },
            }}
            //이벤트 리스너
            onEnd={(e) => {
              e.target.stopVideo(0);
            }}
          />
        </div>
      </section>
      <section className="mb-[528px]">
        <div className="text-whiteGray Mid:text-[40px] Mobile:text-[16px] text-center mb-[46px]">
          이미 많은 트레이너와 운동인들의 {innerWidth < 782 && <br />}관심을
          받고 있어요
        </div>
        <PageMain6_photos />
      </section>
      <section className="grid grid-cols-1 place-content-center place-items-center mb-[484px]">
        <div className="text-whiteGray Mid:text-[50px] Mobile:text-[20px] font-semibold Mid:mb-[16px]">
          운동의 비직관성을 끝내다
        </div>
        <div className="Mid:text-3xl Mobile:text-[14px] font-normal text-whiteGray Mobile:mb-[46px]">
          차원이 다른 가치를 경험해 보세요
        </div>
        <button
          className="bg-bubblePurple text-whiteGray Mid:text-[20px] Mobile:text-[12px]
        Mid:w-[194px] Mobile:w-[110px] Mid:h-[58px] Mobile:h-[39px] rounded Mobile:mb-[150px] font-semibold"
          onClick={() => setInquiryModalOn(true)}
        >
          간편 문의하기
        </button>
        {inquiryModalOn && (
          <InquiryPopup closeModal={() => setInquiryModalOn(!inquiryModalOn)}>
            <InquiryContext />
          </InquiryPopup>
        )}
      </section>
    </div>
  );
}

export default PageMain6;
