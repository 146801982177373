import React, { useState, useEffect } from "react";
import ShowNavBar from "./ShowNavBar";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

const Header = () => {
  const [esc, setEsc] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });
  return (
    <>
      {esc === false ? (
        <>
          <a
            href="https://layercorp.notion.site/layercorp/WORKWAY-56cc4536b9ce4521ae4e5947bdd37918"
            className="flex"
          >
            <header
              className="Mid:h-[68px] Mobile:h-[60px] fixed top-0 w-full 
        bg-gradient-to-r from-myBlack via-bubblePurple to-myBlack"
            >
              <div className="flex justify-center">
                <div className="mr-auto text-clear ml-[40px] text-3xl mt-[17px]"></div>
                <div className="flex mt-[20px]">
                  <div
                    className="Mid:w-[127px] Mid:h-[24px] Mobile:w-0
                    bg-[url('./images/workwayLogoWhite.png')] bg-contain bg-no-repeat mt-[6px]
                    "
                  />
                  <div className="text-white text-center font-semibold Mid:text-[20px] Mobile:text-[16px]">
                    &nbsp;가장 먼저 체험하기! 테스터 모집&nbsp;
                  </div>
                  {innerWidth < 768 ? (
                    <ChevronRightIcon className="text-white w-[17px] h-[17px] mt-[4px]" />
                  ) : (
                    <div className="text-white Mid:font-[16px] ml-[20px] mt-[3px]">
                      자세히 보기 →
                    </div>
                  )}
                </div>
                <a
                  className="ml-auto text-white mr-[40px] text-3xl mt-[17px]"
                  onClick={() => setEsc(true)}
                  href="javascript:void(0);"
                >
                  ✕
                </a>
              </div>
            </header>
          </a>
          <header className="fixed Mid:top-[68px] Mobile:top-[60px] w-full">
            <ShowNavBar />
          </header>
        </>
      ) : (
        <header className="fixed top-[0px] w-full">
          <ShowNavBar />
        </header>
      )}
    </>
  );
};

export default Header;

//.customButton{color:red;}
