import Header from "./layouts/Header";
import PageMain from "./pages/PageMain/PageMain";
import PageService from "./pages/PageService/PageService";
// import PagePrice from "./pages/PagePrice";
import Footer from "./layouts/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="relative">
      <div className="absolute z-50">
        <Header />
      </div>
      <div className="z-30">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PageMain />}></Route>
            <Route path="/pages/PageService" element={<PageService />}></Route>
            {/* <Route path="/pages/PagePrice" element={<PagePrice />}></Route> */}
          </Routes>
        </BrowserRouter>
      </div>

      <Footer />
    </div>
  );
}

export default App;
