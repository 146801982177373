import tw from "tailwind-styled-components";

//input style 관리
export const Input = tw.input`
    Mid:text-[16px]
    Mobile:text-[12px]
    bg-white
    px-[11px]
    py-[7px]
    border-2
    border-mediumGray
    rounded-lg
    focus:border-darkGray
    text-black
    placeholder-placeholderGray
    w-full
`;

export const Textarea = tw.textarea`
    Mid:text-[16px]
    Mobile:text-[12px]
    bg-white
    block p-[10px] 
    w-full
    placeholder-placeholderGray
    border-2
    border-mediumGray
    rounded-lg
`;
