import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import PageMain1 from "./PageMain1";
import PageMain2 from "./PageMain2";
import PageMain3 from "./PageMain3";
import PageMain4 from "./PageMain4";
import PageMain5 from "./PageMain5";
import PageMain6 from "./PageMain6";

function ScrollAnimation({ context }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: false }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        y: { duration: 1 },
        delayChildren: 0.5,
      }}
    >
      <div>{context}</div>
    </motion.div>
  );
}

function PageMain() {
  return (
    <div className="flex flex-col justify-content: center">
      <section className="flex items-center justify-center h-screen overflow-hidden ">
        <div className="">
          <PageMain1 />
        </div>
        <video
          autoPlay
          loop
          muted
          playsinline
          className="absolute object-none w-auto min-w-full min-h-screen -z-10 max-w-screen"
        >
          <source src="video/pageMain1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>
      <section className="flex flex-col w-full Mid:h-[1210px] Mobile:h-[621px] bg-gradient-to-b from-70% from-myBlack to-white bg-top bg-cover">
        <ScrollAnimation context={<PageMain2 />} />
      </section>
      <section className="flex flex-col w-full Mid:h-[2100px] Mobile:h-[1120px] bg-white bg-top bg-cover md:h-screen ">
        <ScrollAnimation context={<PageMain3 />} />
      </section>
      <section className="flex w-full Mid:h-[427px] Mobile:h-[226px] bg-whiteGray">
        <div className="bg-[url('./images/pageMain/graph.png')] w-screen Mid:h-[427px] Mobile:h-[226px] bg-contain bg-bottom bg-no-repeat">
          <ScrollAnimation context={<PageMain4 />} />
        </div>
      </section>
      <section className="flex flex-col w-full Mid:h-[4381px] Mobile:h-[2336px] bg-white">
        <ScrollAnimation context={<PageMain5 />} />
      </section>
      <section className="flex flex-col w-full Mid:h-[3900px] Mobile:h-[3036px] bg-gradient-to-t from-myBlack from-85% to-white">
        <ScrollAnimation context={<PageMain6 />} />
      </section>
    </div>
  );
}

export default PageMain;
