import React, { useState, useEffect } from "react";

import InquiryPopup from "../../modals/Inquiry/InquiryPopup";
import InquiryContext from "../../modals/Inquiry/InquiryContext";

function PageService1() {
  const [inquiryModalOn, setInquiryModalOn] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    console.log(innerWidth);
  });

  return (
    <div className=" w-full Mid:h-[1400px] Mobile:h-[707px] Mobile:mt-[120px] Mid:mt-[60px] flex flex-col justify-center text-whiteGray">
      {innerWidth < 1020 && (
        <div
          className="bg-[url('./images/pageService/pageService1.png')] 
          Mobile:w-[280px] Mid:w-[465px] Mobile:h-[280px] Mid:h-[465px] 
          bg-contain bg-no-repeat self-center"
        />
      )}
      <div className="flex flex-row Mobile:text-center Service_1:text-left">
        <div>
          <div className="Mid:text-[58px] Mobile:text-[26px] font-bold Mid:mb-[20px] Mobile:mb-[11px]">
            운동 경험의 혁신
            <br />
            <div className="flex Service_1:justify-start Mobile:justify-center">
              <div
                className="bg-[url('./images/workwayLogoWhite.png')] 
          Mid:w-[360px] Mobile:w-[143px] Mid:h-[71px] Mobile:h-[29px]
          bg-contain bg-no-repeat Mid:translate-y-5 Mobile:translate-y-2.5"
              />
              와 함께
            </div>
          </div>
          <div className="Mid:text-[30px] Mobile:text-[16px] Mid:mb-[62px] Mobile:mb-[30px]">
            고객이 원하던 직관적인 운동 경험의 모든 것<br />
            운영부터 유입까지 한번에 관리해 보세요
          </div>
          <button
            className="Mid:bg-bubblePurple Mobile:bg-whiteGray
            Mid:text-whiteGray Mobile:text-deepdarkGray
            font-semibold Mid:text-[20px] Mobile:text-[12px]
        Mid:w-[194px] Mobile:w-[110px] Mid:h-[58px] Mobile:h-[39px] rounded Mobile:mb-[150px]"
            onClick={() => setInquiryModalOn(true)}
          >
            간편 문의하기
          </button>
          {inquiryModalOn && (
            <InquiryPopup closeModal={() => setInquiryModalOn(!inquiryModalOn)}>
              <InquiryContext />
            </InquiryPopup>
          )}
        </div>
        {innerWidth >= 1020 && (
          <div
            className="bg-[url('./images/pageService/pageService1.png')] 
          Big:w-[450px] Mid:w-[34vw] Mobile:w-[342px] Big:h-[450px] Mid:h-[37vw] Mobile:h-[219px] 
          bg-contain bg-no-repeat Big:ml-[130px] Mid:ml-[5vw]
          Big:mr-[0px] Mid:mr-[3vw]"
          />
        )}
      </div>
    </div>
  );
}

export default PageService1;
