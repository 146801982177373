import React, { useState } from "react";
import tw from "tailwind-styled-components";

import InquiryPopup from "../../modals/Inquiry/InquiryPopup";
import InquiryContext from "../../modals/Inquiry/InquiryContext";

const Title = tw.div`
grid items-center 
bg-deepdarkGray
Big:w-[354px] Mid:w-[27vw] Mobile:w-[343px]
Big:h-[67px] Mid:h-[5vw] Mobile:h-[65px]
rounded-t-[20px] text-whiteGray
Mid:text-[22px] Mobile:text-[18px]
`;

const Context = tw.div`
grid items-center shadow text-start bg-white
        Big:w-[354px] Mid:w-[27vw] Mobile:w-[343px] Mid:h-auto rounded-b-[20px] text-mediumGray text-[16px]`;

const SmallTitle = tw.div`
        bg-mediumGray 
        Mid:px-[9px]  Mobile:h-[25px] 
        text-whiteGray text-[16px] rounded-[6px] text-center mb-[12px]
        `;

function PageService3_3() {
  const [inquiryModalOn, setInquiryModalOn] = useState(false);

  return (
    <div>
      <div
        className="Mid:mt-[398px] Mid:h-[1768px] Mobile:h-[2556px] text-center 
    Mid:text-[40px] Mobile:text-[20px] font-normal Mid:mb-[600px] Mobile:mt-[40px]"
      >
        <div>
          <span className="font-semibold">제품 구성</span>을 확인해 보세요
        </div>

        <div className="flex Mid:flex-row Mobile:flex-col Big:gap-[49px] Mid:gap-[3vw] Mobile:gap-[40px] mt-[50px]">
          <div className="flex-col">
            <Title>웨어러블 기기</Title>
            <Context>
              <div
                className="bg-[url('./images/pageService/pageService3_3_1.png')] 
        Big:w-[245px] Mid:w-[19vw] Mobile:w-[237px] Big:h-[146px] Mid:h-[12vw] Mobile:h-[141px]
        Big:mx-[55px] Mid:mx-[4vw] Mobile:mx-[52px] Big:my-[89px] Mid:my-[7vw] Mobile:my-[85px]
        bg-contain bg-no-repeat"
              />
              <section className="Big:px-[28px] Mid:px-[2vw] Mobile:px-[23px]">
                <SmallTitle className="w-[58px]">클립</SmallTitle>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 근활성도 측정 AI 센서
                </div>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 전용 웨어의 포켓에 꽂아 원하는 부위에 부착
                </div>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 허브와 무선 통신
                </div>
                <div className="Mid:mb-[30px] Mobile:mb-[28px]">
                  ⋅ 최대 8개까지 부착 가능/set
                </div>
                <SmallTitle className="w-[58px]">허브</SmallTitle>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 운동 데이터 통신을 위한 전용 허브
                </div>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 핸드폰과 무선 통신
                </div>
                <div className="Mid:mb-[30px] Mobile:mb-[28px]">⋅ 1개/set</div>
                <SmallTitle className="w-[60px]">충전독</SmallTitle>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 클립 및 허브 충전을 위한 전용 충전독
                </div>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 고속 무선 충전 지원
                </div>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 간편 탈부착
                </div>
                <div className="mb-[22px]">⋅ 1개/set</div>
              </section>
            </Context>
          </div>

          <div className="flex-col">
            <Title>전용 웨어</Title>
            <Context>
              <div
                className="bg-[url('./images/pageService/pageService3_3_2.png')] 
        Big:w-[354px] Mid:w-[27vw] Mobile:w-[343px] Big:h-[300px] Mid:h-[24vw] Mobile:h-[291px]
        bg-contain bg-no-repeat"
              />
              <section className="Big:px-[28px] Mid:px-[2vw] Mobile:px-[23px]">
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 타이즈 소재의 스포츠 웨어
                </div>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 클립(웨어러블 기기) 부착을 위한 포켓
                </div>
                <div className="Mid:mb-[34px] Mobile:mb-[28px]">
                  ⋅ 빠른 땀 흡수 및 배출
                </div>
                <SmallTitle className="w-[60px]">사이즈</SmallTitle>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  상의 - XS(80), S(85), M(90), L(100), XL(105)
                </div>
                <div className="Mid:mb-[34px] Mobile:mb-[28px]">
                  하의 - 출시 준비 중
                </div>
                <SmallTitle className="Mid:w-[110px] Mobile:w-[105px]">
                  부착 가능 위치
                </SmallTitle>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  상의 - 가슴, 어깨, 승모, 광배, 이두, 삼두
                </div>
                <div className="mb-[22px]">하의 - 출시 준비 중</div>
              </section>
            </Context>
          </div>

          <div className="flex-col">
            <Title>APP</Title>
            <Context>
              <div
                className="bg-[url('./images/pageService/pageService3_3_3.png')] 
        Big:w-[98px] Mid:w-[7vw] Mobile:w-[95px] Big:h-[208px] Mid:h-[17vw] Mobile:h-[202px]
        Big:mx-[128px] Mid:mx-[9vw] Mobile:mx-[124px] Big:my-[46px] Mid:my-[3vw] Mobile:my-[44px]
        bg-contain bg-no-repeat"
              />
              <section className="Big:px-[28px] Mid:px-[2vw] Mobile:px-[23px]">
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 간편한 운동 루틴 생성 및 수정
                </div>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ AI 데이터 알고리즘을 통한 실시간 운동 분석
                </div>
                <div className="mb-[2px]">
                  ⋅ 운동 중 세트별, 횟수별 분석 리포트 제공
                </div>
                <div className="Mid:mb-[10px] Mobile:mb-[9px] text-[12px]">
                  &nbsp;&nbsp;&nbsp;&nbsp;(횟수별 운동 점수, 좌우 밸런스 분석,
                  근활성도 그래픽)
                </div>
                <div className="mb-[2px]">⋅ 각 기준별 분석 정보 제공</div>
                <div className="Mid:mb-[10px] Mobile:mb-[9px] text-[12px]">
                  &nbsp;&nbsp;&nbsp;&nbsp;(기간별, 운동별, 부위별 점수 비교 등)
                </div>
                <div className="Mid:mb-[10px] Mobile:mb-[9px]">
                  ⋅ 운동 캘린더 등 세부 운동 지표 자동 기록
                </div>
                <div className="mb-[22px]">
                  ⋅ 회원 관리 기능 등 추가 기능 업데이트 예정
                </div>
              </section>
            </Context>
          </div>
        </div>
        <button
          className="bg-deepdarkGray text-whiteGray
            font-semibold Mid:text-[20px] Mobile:text-[12px]
        Mid:w-[194px] Mobile:w-[110px] Mid:h-[58px] Mobile:h-[39px] rounded Mobile:mb-[150px]
        Mobile:mt-[40px]"
          onClick={() => setInquiryModalOn(true)}
        >
          간편 문의하기
        </button>
      </div>
      {inquiryModalOn && (
        <InquiryPopup closeModal={() => setInquiryModalOn(!inquiryModalOn)}>
          <InquiryContext />
        </InquiryPopup>
      )}
    </div>
  );
}

export default PageService3_3;
