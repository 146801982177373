import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";

const Photo = tw.div`

        Big:w-[675px] Mid:w-[47vw] Mobile:w-[181px] Big:h-[730px] Mid:h-[60vw] Mobile:h-[195px]
        bg-contain bg-no-repeat
`;

function PageService3_4() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });
  return (
    <div
      className="Mid:h-[3000px] Mobile:h-[1139px] flex flex-col justify-center text-center text-deepdarkGray
    Mid:text-[40px] Mobile:text-[20px] "
    >
      <div className="flex flex-row justify-center Mid:mb-[346px]">
        3단계의 간단한&nbsp; <div className="font-semibold">서비스 플로우</div>
        를 {innerWidth >= 782 && <>경험해 보세요</>}
      </div>
      {innerWidth < 782 && <div className="mb-[100px]">경험해 보세요</div>}
      <div className="flex flex-row justify-center">
        <div
          className="grid items-start mb-[40px] Big:gap-[208px] Mid:gap-[0vw] Mobile:gap-[36px]
      Big:px-0 Mid:px-[8vw] Big:w-[1159px] Mid:w-[89vw]"
        >
          <div
            className="grid grid-flow-col grid-rows-2 Mid:w-[320px] Mobile:w-[126px] 
          Mid:h-[200px] Mobile:h-[71px] Mid:font-semibold text-start"
          >
            <div className="Mid:text-[100px] Mobile:text-[40px]">1</div>
            <div className="col-span-2 col-start-2 row-start-2 Mid:mt-10 Mobile:-mt-4 ml-[9px]">
              전용 웨어를
              <br />
              착용하세요
            </div>
          </div>
          <Photo className="col-start-2 bg-[url('./images/pageService/pageService3_4_1.png')]"></Photo>
        </div>
      </div>

      <div className="flex flex-row justify-center">
        <div
          className="grid items-start mb-[40px] Big:gap-[208px] Mid:gap-[0vw] Mobile:gap-[19px]
      Big:px-0 Mid:px-[8vw] Big:w-[1159px] Mid:w-[89vw]"
        >
          <div
            className="grid grid-flow-col grid-rows-2 Mid:w-[320px] Mobile:w-[150px] 
          Mid:h-[200px] Mobile:h-[71px] Mid:font-semibold text-start"
          >
            <div className="Mid:text-[100px] Mobile:text-[40px]">2</div>
            <div className="col-span-2 col-start-2 row-start-2 Mid:mt-10 Mobile:-mt-4 ml-[9px]">
              기기를 <br />
              연결하고,
              <br /> 웨어의 포켓에 <br />
              클립을 <br />
              부착하세요
            </div>
          </div>
          <Photo className="col-start-2 bg-[url('./images/pageService/pageService3_4_2.png')] "></Photo>
        </div>
      </div>
      <div
        className="grid items-start mb-[40px] Big:gap-[208px] Mid:gap-[0vw] Mobile:gap-[19px]
      Big:px-0 Mid:px-[8vw] Big:w-[1159px] Mid:w-[89vw]"
      >
        <div
          className="grid grid-flow-col grid-rows-2 Mid:w-[320px] Mobile:w-[150px] 
          Mid:h-[200px] Mobile:h-[71px] Mid:font-semibold text-start"
        >
          {" "}
          <div className="Mid:text-[100px] Mobile:text-[40px]">3</div>
          <div className="col-span-2 col-start-2 row-start-2 Mid:mt-10 Mobile:-mt-4 ml-[9px]">
            실시간으로 <br />
            분석된 지표를 <br />
            확인하며 <br />
            운동을 <br />
            진행하세요
          </div>
        </div>
        <Photo className="col-start-2 bg-[url('./images/pageService/pageService3_4_3.png')] "></Photo>
      </div>
    </div>
  );
}

export default PageService3_4;
