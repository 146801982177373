import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";

const Box = tw.div`
  Big:w-[696px] Mid:w-[53vw] Mobile:w-[343px] Big:h-[339px] Mid:h-[28vw] Mid:pb-0 Mobile:pb-[16px] bg-white rounded-[20px] shadow
  Big:py-[50px] Mid:py-[3vw] Mobile:p-[16px] Big:px-[52px] Mid:px-[4vw]
`;
const ProfileImage = tw.div`
Big:w-[100px] Mid:w-[7vw] Mobile:w-[60px] Big:h-[100px] Mid:h-[8vw] Mobile:h-[60px]
        mr-[15px] Big:mb-[40px] Mid:mb-[1vw] Mobile:mb-[8px]
        bg-contain bg-no-repeat
`;

function Review1() {
  return (
    <div>
      <div className="flex flex-row">
        <ProfileImage className="bg-[url('./images/pageService/pageService3_5_1.png')]" />
        <div className="flex Mid:text-[18px] Mobile:text-xs text-mediumGray Big:mt-[40px] Mid:mt-[3vw] Mobile:mt-6">
          <div className="font-semibold">테스트 참가자&nbsp;</div> 박**님
        </div>
      </div>
      <div className="Big:text-2xl Mid:text-xl Mobile:text-sm text-deepdarkGray text-start">
        운동을 하면서 ‘내가 적절한 근육을 쓰고 있는지'를 확인하는 것이 가장
        어려웠는데, 세트 중간중간 실시간으로 분석 자료를 볼 수 있어서 좋았어요.
      </div>
    </div>
  );
}

function Review2() {
  return (
    <div>
      <div className="flex flex-row">
        <ProfileImage className="bg-[url('./images/pageService/pageService3_5_2.png')]" />
        <div className="flex Mid:text-[18px] Mobile:text-xs text-mediumGray Big:mt-[40px] Mid:mt-[3vw] Mobile:mt-6">
          <div className="font-semibold">** 피트니스 센터 트레이너&nbsp;</div>
          김**님
        </div>
      </div>
      <div className="Big:text-2xl Mid:text-xl Mobile:text-sm text-deepdarkGray text-start">
        PT 수업 중 회원분께 분석된 근육의 활성도를 보여 드리니 너무 좋아하셔서
        저도 덩달아 수업에 자신감이 생겼습니다. 한 번 써 보면 빠져나오기 힘드실
        거예요!
      </div>
    </div>
  );
}

function Review3() {
  return (
    <div>
      <div className="flex flex-row">
        <ProfileImage className="bg-[url('./images/pageService/pageService3_5_3.png')]" />
        <div className="flex Mid:text-[18px] Mobile:text-xs text-mediumGray Big:mt-[40px] Mid:mt-[3vw] Mobile:mt-6">
          <div className="font-semibold">** 피트니스 센터 대표&nbsp;</div>
          김**님
        </div>
      </div>
      <div className="Big:text-2xl Mid:text-xl Mobile:text-sm text-deepdarkGray text-start">
        WORKWAY 테스트 도입 이후, 체험해 보신 회원분들의 자발적인 PT 문의가
        계속해서 늘고 있어요. 장기간 사용 시 더 확실한 고객 유입 효과가 있을
        거라 판단되어 도입을 결정했습니다.
      </div>
    </div>
  );
}
function PageService3_5() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });
  return (
    <div
      className="Mid:h-[1392px] Mobile:h-[803px] flex flex-col justify-center text-center text-deepdarkGray 
    Mid:text-[40px] Mobile:text-xl"
    >
      <div className="flex flex-row justify-center Mobile:mb-[50px] Mid:mb-0">
        <div className="font-semibold">실제 사용 후기</div>를 확인해 보세요
      </div>
      {innerWidth >= 782 ? (
        <div
          x-data="{}"
          x-init="$nextTick(() => {
        let ul = $refs.logos;
        ul.insertAdjacentHTML('afterend', ul.outerHTML);
        ul.nextSibling.setAttribute('aria-hidden', 'true');
    })"
          class="Mid:mt-[130px] max-w-prose mx-auto px-4 md:px-6 w-full inline-flex flex-nowrap
        overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]"
        >
          <ul
            x-ref="logos"
            class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
          >
            <li>
              <Box>
                <Review1 />
              </Box>
            </li>
            <li>
              <Box>
                <Review2 />
              </Box>
            </li>
            <li>
              <Box>
                <Review3 />
              </Box>
            </li>
          </ul>
          <ul
            class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
            aria-hidden="true"
          >
            <li>
              <Box>
                <Review1 />
              </Box>
            </li>
            <li>
              <Box>
                <Review2 />
              </Box>
            </li>
            <li>
              <Box>
                <Review3 />
              </Box>
            </li>
          </ul>
        </div>
      ) : (
        <div className="grid grid-cols-1 place-items-center gap-[20px]">
          <Box>
            <Review1 />
          </Box>
          <Box>
            <Review2 />
          </Box>
          <Box>
            <Review3 />
          </Box>
        </div>
      )}
    </div>
  );
}

export default PageService3_5;
