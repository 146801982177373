import React, { useState } from "react";

import InquiryPopup from "../../modals/Inquiry/InquiryPopup";
import InquiryContext from "../../modals/Inquiry/InquiryContext";

function PageService6() {
  const [inquiryModalOn, setInquiryModalOn] = useState(false);
  return (
    <div className="text-whiteGray Mid:h-[1080px] Mobile:h-[506px] grid grid-cols-1 place-content-center place-items-center text-center">
      <div className="flex Mid:text-[50px] Mobile:text-[20px] font-bold Mid:mb-[16px] Mobile:mb-[11px]">
        피트니스의 혁신,&nbsp;
        <div
          className="bg-[url('./images/workwayLogoWhite.png')] 
          Mid:w-[360px] Mobile:w-[143px] Mid:h-[71px] Mobile:h-[29px]
          bg-contain bg-no-repeat Mid:translate-y-3 Mobile:translate-y-1.5"
        />
      </div>
      <div className="Mid:text-3xl Mobile:text-[14px] font-normal Mid:mb-[46px] Mobile:mb-[20px]">
        지금 바로 시작해 보세요
      </div>
      <button
        className="bg-bubblePurple text-whiteGray Mid:text-[20px] Mobile:text-[12px]
         Mid:w-[194px] Mobile:w-[110px] Mid:h-[58px] Mobile:h-[39px] rounded font-semibold"
        onClick={() => setInquiryModalOn(true)}
      >
        간편 문의하기
      </button>
      {inquiryModalOn && (
        <InquiryPopup closeModal={() => setInquiryModalOn(!inquiryModalOn)}>
          <InquiryContext />
        </InquiryPopup>
      )}
    </div>
  );
}

export default PageService6;
