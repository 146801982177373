import React, { useState, useEffect } from "react";

function PageService3_1() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });
  return (
    <div
      className="Mid:h-[1410px] Mobile:h-[413px] text-center flex flex-col justify-center
    Mid:text-[40px] Mobile:text-[20px] font-normal Mid:mb-[600px] "
    >
      <div>
        {innerWidth >= 782 ? (
          <>
            <span className="font-semibold">
              AI 웨어러블 기반 운동 분석 솔루션
            </span>
            을 통해
            <br />더 나은 운동 생태계를 지원합니다
          </>
        ) : (
          <>
            <span className="font-semibold">
              AI 웨어러블 기반
              <br />
              운동 분석 솔루션
            </span>
            을 통해
            <br />더 나은 운동 생태계를 지원합니다
          </>
        )}
      </div>

      <div
        className="bg-[url('./images/pageService/pageService3_1.png')]
        Big:w-[1161px] Mid:w-[89vw] Mobile:w-[346px] Mid:h-[352px] Mobile:h-[101px]
        Mid:bg-contain Mobile:bg-cover bg-no-repeat Mid:mt-[196px] Mobile:mt-[50px]"
      />
    </div>
  );
}

export default PageService3_1;
