import React, { useState, useEffect } from "react";

function PageMain2() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    console.log(innerWidth);
  });
  //768~782 이상함
  return (
    <div className="translate Big:translate-y-[270px] Mid:translate-y-[22vw] Mobile:-translate-y-[100px] Mid:h-[1210px] Mobile:h-[621px] grid grid-cols-1 text-center place-content-center place-items-center text-whiteGray">
      <div className="Mid:text-[30px] Mobile:text-[14px] font-normal">
        과열된 피트니스 시장 속
      </div>
      <div className="Mid:text-[50px] Mobile:text-[20px] font-bold">
        센터 운영에 고민이 있으신가요?
      </div>
      <div className="transform Mid:translate-x-[21px]">
        {innerWidth >= 768 && (
          <>
            <div
              className="transform Big:-translate-x-[200px] Mid:-translate-x-[15vw] Mid:translate-y-[49px] 
            Big:w-[341px] Mid:w-[26vw] Big:h-[117px] Mid:h-[9vw] 
            bg-bubblePurple bg-opacity-20 
            rounded-tr-[20px] rounded-bl-[20px] rounded-br-[20px]"
            />
            <div
              className="transform Big:translate-x-[350px] Mid:translate-x-[26vw] Big:-translate-y-[60px] Mid:-translate-y-[4vw] 
            flex place-content-center place-items-center 
            Big:w-[489px] Mid:w-[37vw] Big:h-[117px] Mid:h-[9vw] mt-16 
            bg-bubblePurple bg-opacity-30 
            rounded-tl-[20px] rounded-bl-[20px] rounded-br-[20px]"
            >
              <span className="Big:text-[20px] Mid:text-[1.7vw] px-[16px] font-normal text-darkGray text-start">
                더 전문적이고 신뢰할 수 있는 센터로 만들고 싶어요.
              </span>
            </div>
          </>
        )}
        <section>
          <div
            className="bg-[url('./images/pageMain/bubblePerson1.png')] bg-contain bg-no-repeat
        transform Big:-translate-x-[230px] Mid:-translate-x-[17vw] Big:-translate-y-[170px] Mid:-translate-y-[14vw]
        Big:w-[117px] Mid:w-[9vw] Mobile:w-[60px] Big:h-[117px] Mid:h-[10vw] Mobile-[60px]
        rounded-full"
          />
          <div
            className="transform Big:-translate-x-[90px] Mid:-translate-x-[6vw] Mobile:translate-x-[0px] Big:-translate-y-[210px] Mid:-translate-y-[17vw] Mobile:translate-y-[73px]
        flex place-content-center place-items-center Big:w-[677px] Mid:w-[58vw] Mobile:w-[230px] Big:h-[117px] Mid:h-[9vw] Mobile:h-[72px] 
        bg-bubblePurple
        Mid:rounded-tr-[20px] Mid:rounded-bl-[20px] Mid:rounded-br-[20px] 
        Mobile:rounded-tr-[8px] Mobile:rounded-bl-[8px] Mobile:rounded-br-[8px]"
          >
            <span className="text-white Big:text-[26px] Mid:text-[2vw] Mobile:text-[15px] font-normal px-[16px] text-start">
              어떻게 하면 보다&nbsp;
              <span className="font-semibold ">
                안정적으로{innerWidth >= 768 ? <>&nbsp;</> : <br />}센터를 운영
              </span>
              할 수 있을까요?
            </span>
          </div>
        </section>
        {innerWidth >= 768 && (
          <>
            <div
              className="transform Big:-translate-x-[250px] Mid:-translate-x-[19vw] Big:-translate-y-[170px] Mid:-translate-y-[14vw]
        flex place-content-center place-items-center Big:w-[447px] Mid:w-[34vw] Big:h-[117px] Mid:h-[9vw] 
        bg-bubblePurple bg-opacity-30 rounded-tr-[20px] rounded-bl-[20px] rounded-br-[20px]"
            >
              <span className="Big:text-[20px] Mid:text-[1.5vw] font-normal text-darkGray px-[16px] text-start">
                다른 센터와의 차별성을 만들고 싶어요.
              </span>
            </div>
          </>
        )}
        <section>
          <div
            className="transform Big:translate-x-[250px] Mid:translate-x-[19vw] Mobile:translate-x-[40px] Big:-translate-y-[230px] Mid:-translate-y-[19vw] Mobile:translate-y-[122px]
        flex place-content-center place-items-center Big:w-[563px] Mid:w-[43vw] Mobile:w-[224px] Big:h-[117px] Mid:h-[9vw] Mobile:h-[95px] 
        bg-bubblePurple 
        Mid:rounded-tl-[20px] Mid:rounded-bl-[20px] Mid:rounded-br-[20px] 
        Mobile:rounded-tl-[8px] Mobile:rounded-bl-[8px] Mobile:rounded-br-[8px]"
          >
            <span className="text-white Big:text-[26px] Mid:text-[2vw] Mobile:text-[15px] font-normal px-[16px] text-start">
              신규, 재등록&nbsp;
              <span className="font-semibold ">PT 수업 등록률</span>을 높이고
              싶은데
              {innerWidth > 768 ? <br /> : <>&nbsp;</>}
              <span className="font-semibold ">일반적인 영업</span>
              으로는 한계가 있어요.
            </span>
          </div>
          <div
            className="bg-[url('./images/pageMain/bubblePerson2.png')] bg-contain bg-no-repeat
          transform Big:translate-x-[840px] Mid:translate-x-[64vw] Big:-translate-y-[400px] Mid:-translate-y-[33vw] 
          Big:w-[117px] Mid:w-[9vw] Mobile:w-[60px] Big:h-[117px] Mid:h-[10vw] Mobile-[60px] rounded-full"
          />
        </section>
        {innerWidth >= 768 && (
          <div
            className="transform Big:translate-x-[650px] Mid:translate-x-[50vw] Big:-translate-y-[320px] Mid:-translate-y-[26vw]
          flex place-content-center place-items-center Big:w-[275px] Mid:w-[21vw] Big:h-[117px] Mid:h-[9vw]
           bg-bubblePurple 
           bg-opacity-30 rounded-tl-[20px] rounded-bl-[20px] rounded-br-[20px]"
          >
            <span className="Big:text-[20px] Mid:text-[1.7vw] px-[16px] font-normal text-darkGray text-start">
              매출을 늘리고 싶어요.
            </span>
          </div>
        )}
        <section>
          <div
            className="bg-[url('./images/pageMain/bubblePerson3.png')] bg-contain bg-no-repeat
          transform Big:-translate-x-[220px] Mid:-translate-x-[17vw] Big:-translate-y-[450px] Mid:-translate-y-[37vw]
          Big:w-[117px] Big:h-[117px] Mid:w-[9vw] Mid:h-[9vw] rounded-full"
          />
          <div
            className="transform Big:-translate-x-[80px] Mid:-translate-x-[6vw] Big:-translate-y-[500px] Mid:-translate-y-[41vw] Mobile:translate-y-[185px]
          flex place-content-center place-items-center Big:w-[438px] Mid:w-[38vw] Mobile:w-[258px] Big:h-[117px] Mid:h-[9vw] Mobile:h-[51px]
          bg-bubblePurple
          Mid:rounded-tr-[20px] Mid:rounded-bl-[20px] Mid:rounded-br-[20px] 
          Mobile:rounded-tr-[8px] Mobile:rounded-bl-[8px] Mobile:rounded-br-[8px]"
          >
            <span className="text-white Big:text-[26px] Mid:text-[2vw] Mobile:text-[15px] font-normal text-start px-[16px]">
              <span className="font-semibold ">워크인 PT 문의</span>를 늘리고
              싶어요.
            </span>
          </div>
        </section>
        {innerWidth >= 768 && (
          <div
            className="transform Big:translate-x-[90px] Mid:translate-x-[6vw] Big:-translate-y-[540px] Mid:-translate-y-[44vw]
          Big:w-[339px] Mid:w-[29vw] Big:h-[117px] Mid:h-[9vw]
          bg-bubblePurple bg-opacity-20 rounded-tr-[20px] rounded-bl-[20px] rounded-br-[20px]"
          />
        )}
      </div>
    </div>
  );
}

export default PageMain2;
