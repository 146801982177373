import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function PageMain3() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });

  return (
    <div className="grid Mid:h-[2100px] Mobile:h-[1120px] grid-cols-1 place-content-center place-items-center">
      <div className="flex  text-deepdarkGray Mid:text-[50px] Mobile:text-[20px] font-normal">
        이제
        <rect className="bg-[url('./images/workwayLogo.png')] translate-y-1 Mid:w-[360px] Mobile:w-[121px] Mid:h-[70px] Mobile:h-[24px] bg-cover bg-no-repeat" />
        가{innerWidth > 855 && <>&nbsp;해결해 줄 거예요!</>}
      </div>
      {innerWidth <= 855 && (
        <div className=" text-deepdarkGray Mid:text-[50px] Mobile:text-[20px] font-normal Mobile:mb-[150px]">
          해결해 줄 거에요!
        </div>
      )}

      <section className="flex flex-col space-y-8">
        <div
          className="Mid:mt-[438px] Big:w-[1159px] Mid:w-[89vw] Mobile:w-[343px] Mid:h-auto Mobile:h-[422px] bg-white 
        Mid:rounded-[20px] Mobile:rounded-[8px] shadow"
        >
          <div className="flex Mobile:flex-col Mid:flex-row Big:px-[30px] Mid:px-[2vw] Big:py-[47px] Mid:py-[3vw] Mobile:p-[16px] Mobile:items-center Mobile:content-center">
            {innerWidth < 768 && (
              <div
                className="Mobile:bg-[url('./images/pageMain/pageMain3.png')] bg-contain bg-no-repeat
                          Mobile:w-[343px] Mobile:h-[213px]"
              />
            )}
            <section className="Big:w-[530px] Mid:w-[40vw] Mobile:w-[311px] Big:pt-[40px] Mid:pt-[3vw] Big:pl-[27px] Mid:pl-[2vw] Big:pb-[17px] Mid:pb-[1vw] Big:pr-[39px] Mid:pr-[3vw]">
              <div className="Big:pb-[11px] Mid:pb-[0.9vw] Mobile:pb-[6px] font-semibold leading-none Mid:text-[16px] Mobile:text-[12px] text-Blue">
                근전도 측정 AI Wearable
              </div>
              <div className="Big:pb-[25px] Mid:pb-[2vw] Mobile:pb-[10px] Mid:text-3xl Mobile:text-[16px] font-semibold text-deepdarkGray">
                감이 아닌 데이터로
                <br />
                정확한 운동을 측정해 보세요
              </div>
              <div className="text-mediumGray Big:pb-[38px] Mid:pb-[3vw] Mid:text-[18px] Mobile:text-[12px] font-normal">
                근전도 측정 기술을 활용한 AI Wearable은 부위별로 <br /> 사용되는
                근육의 활성도를 정량적인 데이터로 도출합니다.
              </div>
              {innerWidth >= 768 && (
                <Link to="/pages/PageService">
                  <button
                    className="Big:w-[194px] Mid:w-[15vw] Big:h-[58px] Mid:h-[4.7vw]
                bg-whiteGray leading-tight text-darkGray rounded-[6px]
                "
                  >
                    자세히 보기
                  </button>
                </Link>
              )}
            </section>
            {innerWidth >= 768 && (
              <div
                className="bg-[url('./images/pageMain/pageMain3.png')] bg-contain bg-no-repeat
                            Big:w-[632px] Mid:w-[48vw] Big:h-[327px] Mid:h-[27vw]"
              />
            )}
            {innerWidth < 768 && (
              <Link to="/pages/PageService">
                <button
                  className="Mobile:w-[99px] Mobile:h-[40px]
               bg-whiteGray Mobile:text-[12px] leading-tight text-darkGray rounded-[6px]
               mt-[22px]
               "
                >
                  자세히 보기
                </button>
              </Link>
            )}
          </div>
        </div>

        <div
          className="Mid:mt-[438px] Big:w-[1159px] Mid:w-[89vw] Mobile:w-[343px] Mid:h-auto Mobile:h-[360px] bg-white 
        Mid:rounded-[20px] Mobile:rounded-[8px] shadow"
        >
          <div className="flex Mobile:flex-col Mid:flex-row Big:px-[30px] Mid:px-[2vw] Big:py-[47px] Mid:py-[3vw] Mobile:p-[16px] Mobile:items-center Mobile:content-center">
            {innerWidth < 768 && (
              <div
                className="Mobile:bg-[url('./images/pageMain/pageMain3-2.png')] bg-contain bg-no-repeat
                          Mobile:w-[229px] Mobile:h-[155px] Mobile:my-[30px]"
              />
            )}
            <section className="Big:w-[530px] Mid:w-[40vw] Mobile:w-[311px] Big:pt-[40px] Mid:pt-[3vw] Big:pl-[27px] Mid:pl-[2vw] Big:pb-[17px] Mid:pb-[1vw] Big:pr-[39px] Mid:pr-[3vw]">
              <div className="Big:pb-[11px] Mid:pb-[0.9vw] Mobile:pb-[6px] font-semibold leading-none Mid:text-[16px] Mobile:text-[12px] text-Blue">
                근활성도 기반 운동 분석 Report
              </div>
              <div className="Big:pb-[25px] Mid:pb-[2vw] Mobile:pb-[10px] Mid:text-3xl Mobile:text-[16px] font-semibold text-deepdarkGray">
                눈으로 볼 수 없던 근육 활성도를
                <br />
                이제는 트레이닝에 활용하세요
              </div>
              <div className="text-mediumGray Big:pb-[38px] Mid:pb-[3vw] Mid:text-[18px] Mobile:text-[12px] font-normal">
                WORKWAY의 자체 개발 AI가 부위별 근육 활성도 데이터를
                <br />
                실시간으로 분석 및 도출하여 운동 리포트를 제공합니다.
              </div>
            </section>
            {innerWidth >= 768 && (
              <div
                className="bg-[url('./images/pageMain/pageMain3-2.png')] bg-contain bg-no-repeat
                            Big:w-[632px] Mid:w-[48vw] Big:h-[327px] Mid:h-[27vw]"
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default PageMain3;
