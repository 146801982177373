import React from "react";
import { motion } from "framer-motion";
import PageService1 from "./PageService1";
import PageService2 from "./PageService2";
import PageService3_1 from "./PageService3_1";
import PageService3_2 from "./PageService3_2";
import PageService3_3 from "./PageService3_3";
import PageService3_4 from "./PageService3_4";
import PageService3_5 from "./PageService3_5";
import PageService4 from "./PageService4";
import PageService5 from "./PageService5";
import PageService6 from "./PageService6";

function ScrollAnimation({ context }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: false }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        y: { duration: 1 },
        delayChildren: 0.5,
      }}
    >
      <div>{context}</div>
    </motion.div>
  );
}

function PageMain() {
  const elements = document.getElementsByClassName("Big");
  return (
    <div className="">
      <section className=" w-full Mid:h-[1200px] Mobile:h-[709px] flex justify-center bg-gradient-to-b from-bubblePurple to-myBlack">
        <ScrollAnimation context={<PageService1 />} />
      </section>
      <section className=" w-full Mid:h-[1080px] Mobile:h-[583px] flex justify-center Mid:bg-black Mobile:bg-gradient-to-b Mobile:from-myBlack from-40% Mobile:to-white">
        <ScrollAnimation context={<PageService2 />} />
      </section>
      <section className="w-full Mid:h-[1410px] Mobile:h-[413px] flex justify-center bg-white">
        <ScrollAnimation context={<PageService3_1 />} />
      </section>
      <section className="w-full Mid:h-[1063px] Mobile:h-[1837px] flex justify-center bg-white">
        <ScrollAnimation context={<PageService3_2 />} />
      </section>
      <section className="w-full Mid:h-[1768px] Mobile:h-[2556px] flex justify-center bg-white">
        <ScrollAnimation context={<PageService3_3 />} />
      </section>
      <section className="w-full Mid:h-[3000px] Mobile:h-[1139px] flex justify-center bg-white">
        <ScrollAnimation context={<PageService3_4 />} />
      </section>
      <section className="w-full Mid:h-[1392px] Mobile:h-[803px] flex justify-center bg-white">
        <ScrollAnimation context={<PageService3_5 />} />
      </section>
      <section className="w-full Mid:h-[1498px] Mobile:h-[770px] flex justify-center bg-gradient-to-t from-myBlack from-30% to-white">
        <ScrollAnimation context={<PageService4 />} />
      </section>
      <section className="w-full Mid:h-[427px] Mobile:h-[226px] flex justify-center bg-black">
        <div className="w-full Mid:h-[427px] Mobile:h-[226px] flex justify-center bg-[url('./images/pageService/pageService5_rectangle.png')]">
          <div className="bg-[url('./images/pageService/pageService5.png')] w-screen Mid:h-[427px] Mobile:h-[226px] bg-contain bg-bottom bg-no-repeat">
            <ScrollAnimation context={<PageService5 />} />
          </div>
        </div>
      </section>
      <section className="w-full Mid:h-[1080px] Mobile:h-[506px] flex justify-center bg-black">
        <ScrollAnimation context={<PageService6 />} />
      </section>
    </div>
  );
}

export default PageMain;
