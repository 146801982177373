import React, { useState } from "react";

import InquiryPopup from "../../modals/Inquiry/InquiryPopup";
import InquiryContext from "../../modals/Inquiry/InquiryContext";

function PageMain1() {
  const [inquiryModalOn, setInquiryModalOn] = useState(false);
  return (
    <div className="grid h-screen grid-cols-1 place-content-center place-items-center text-whiteGray">
      <div className="Mid:text-[30px] Mobile:text-[16px] font-normal">
        스마트한 센터 운영을 위한 모든 것
      </div>
      <div className="Mid:text-[58px] Mobile:text-[24px] font-bold text-center">
        AI 웨어러블 기반 운동 분석 솔루션
      </div>
      <button
        onClick={() => setInquiryModalOn(true)}
        className="bg-whiteGray text-deepdarkGray Mid:text-[20px] Mobile:text-[12px] 
        Mid:w-[194px] Mobile:w-[110px] font-semibold Mid:py-[19px] Mobile:py-[13px] 
        Mid:mt-[40px] Mobile:mt-[15px] rounded"
      >
        간편 문의하기
      </button>
      {inquiryModalOn && (
        <div className="absolute z-50">
          <InquiryPopup closeModal={() => setInquiryModalOn(!inquiryModalOn)}>
            <InquiryContext />
          </InquiryPopup>
        </div>
      )}
    </div>
  );
}

export default PageMain1;
