import React, { useState, useEffect } from "react";

function PageMain6_photos() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    //console.log(innerWidth);
  });
  return (
    <>
      {innerWidth >= 782 ? (
        <>
          <div className="flex justify-center gap-[15px] mb-[32px]">
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image9.png')] bg-contain bg-no-repeat
            Big:w-[166px] Mid:w-[12vw] Big:h-[248px] Mid:h-[20vw]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image10.png')] bg-contain bg-no-repeat
          Big:w-[166px] Mid:w-[12vw] Big:h-[248px] Mid:h-[20vw]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image12.png')] bg-contain bg-no-repeat
            Big:w-[437px] Mid:w-[33vw] Big:h-[248px] Mid:h-[20vw]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image8.png')] bg-contain bg-no-repeat
          Big:w-[166px] Mid:w-[12vw] Big:h-[248px] Mid:h-[20vw]"
            />

            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image5.png')] bg-contain bg-no-repeat
          Big:w-[166px] Mid:w-[12vw] Big:h-[248px] Mid:h-[20vw]"
            />
          </div>
          <div className="flex justify-center gap-[15px]">
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image7.png')] bg-contain bg-no-repeat
          Big:w-[166px] Mid:w-[12vw] Big:h-[248px] Mid:h-[20vw]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image4.png')] bg-contain bg-no-repeat
          Big:w-[166px] Mid:w-[12vw] Big:h-[248px] Mid:h-[20vw]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image6.png')] bg-contain bg-no-repeat
          Big:w-[166px] Mid:w-[12vw] Big:h-[248px] Mid:h-[20vw]"
            />

            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image12.png')] bg-contain bg-no-repeat
          Big:w-[437px] Mid:w-[33vw] Big:h-[248px] Mid:h-[20vw]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image11.png')] bg-contain bg-no-repeat
          Big:w-[166px] Mid:w-[12vw] Big:h-[248px] Mid:h-[20vw]"
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center gap-[7px] mb-[7px]">
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image10.png')] bg-contain bg-no-repeat
              w-[71px] h-[107px]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image12.png')] bg-contain bg-no-repeat
            w-[187px] h-[107px]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image8.png')] bg-contain bg-no-repeat
          w-[71px] h-[107px]"
            />
          </div>
          <div className="flex justify-center gap-[7px] mb-[7px]">
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image4.png')] bg-contain bg-no-repeat
          w-[71px] h-[107px]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image6.png')] bg-contain bg-no-repeat
          w-[71px] h-[107px]"
            />

            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image12.png')] bg-contain bg-no-repeat
          w-[187px] h-[107px]"
            />
          </div>
          <div className="flex justify-center gap-[7px]">
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image9.png')] bg-contain bg-no-repeat
            w-[80px] h-[120px]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image5.png')] bg-contain bg-no-repeat
          w-[80px] h-[120px]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image7.png')] bg-contain bg-no-repeat
          w-[80px] h-[120px]"
            />
            <div
              className="grayscale bg-[url('./images/pageMain/pageMain6-image11.png')] bg-contain bg-no-repeat
          w-[80px] h-[120px]"
            />
          </div>
        </>
      )}
    </>
  );
}

export default PageMain6_photos;
