import React from "react";

function PageService4() {
  return (
    <div
      className="Mid:h-[1498px] Mobile:h-[770px] text-center justify-center flex flex-col
    Mid:text-[40px] Mobile:text-[20px] text-whiteGray"
    >
      <div className="flex flex-row justify-center">
        이제&nbsp;
        <div
          className="Mid:w-[260px] Mobile:w-[121px] Mid:h-[41px] Mobile:h-[24px] 
        bg-[url('./images/workwayLogoWhite.png')] bg-contain bg-no-repeat 
        Mid:translate-y-2.5 Mobile:translate-y-1.5"
        ></div>
        &nbsp;도입으로
      </div>
      센터 운영의 효율성을 극대화시키세요
      <div className="flex flew-row justify-center Big:gap-[66px] Mid:gap-[5vw] Mid:mt-[184px] Mobile:mt-[50px]">
        <div
          className="Big:w-[322px] Mid:w-[24vw] Mobile:w-[126px] Big:h-[465px] Mid:h-[38vw] Mobile:h-[243px]
        bg-[url('./images/pageService/pageService4_1.png')] bg-contain bg-no-repeat"
        ></div>
        <div
          className="Big:w-[322px] Mid:w-[24vw] Mobile:w-[126px] Big:h-[465px] Mid:h-[38vw] Mobile:h-[243px]
        bg-[url('./images/pageService/pageService4_2.png')] bg-contain bg-no-repeat translate-y-7"
        ></div>
      </div>
    </div>
  );
}

export default PageService4;
