import React, { useState, useEffect } from "react";

function PageService2() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    console.log(innerWidth);
  });
  return (
    <div
      className="grid place-items-center text-center grld-cols-1
    text-whiteGray Mid:text-[50px] Mobile:text-[20px] font-semibold Mobile:mt-[110px]"
    >
      최적화된 운동 경험을 제공합니다
      <div className="Mid:text-[30px] Mobile:text-[16px] font-normal mb-[48px]">
        감에 의존하던 운동도, 영업에 대한 고민도
        {innerWidth >= 780 ? <>&nbsp;</> : <br />}전부 해결해 드릴게요
      </div>
      <div
        className="bg-[url('./images/pageService/pageService2.png')]
        Big:w-[1165px] Mid:w-[89vw] Mobile:w-[343px] Mid:h-[752px] Mobile:h-[221px]
        bg-contain bg-no-repeat"
      />
    </div>
  );
}

export default PageService2;
